import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Table as Mantinetable,
  Modal,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { useDebouncedValue } from "@mantine/hooks";
import {
  IconCalendarDue,
  IconChecks,
  IconChevronDown,
  IconClearAll,
  IconEdit,
  IconExclamationMark,
  IconEyeCheck,
  IconInfoSquare,
  IconSquareX,
} from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { SupplierSalesDynamicConfirmForm } from "../../components/supplier-sales/confirm-form";
import { SupplierSalesDeliveryForm } from "../../components/supplier-sales/delivery-form";
import { SupplierSalesDynamicReviewForm } from "../../components/supplier-sales/review-form";
import { CustomTable, ITableRef } from "../../components/table/customTable";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IOrder } from "../../interfaces/IOrder";
import { IPermissions } from "../../interfaces/IPermissions";
import { tugrug } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function SupplierSalesList({ setChecked, actions, checked, setActions }: { actions?: any; setChecked?: any; checked?: any; setActions?: any }) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [orderType, setOrderType] = React.useState("");
  const [debouncedOrderType] = useDebouncedValue(orderType, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);
  const [invoiceType, setInvoiceType] = React.useState("");
  const [debouncedInvoiceType] = useDebouncedValue(invoiceType, 600);
  const [paymentType, setPaymentType] = React.useState("");
  const [debouncedPaymentType] = useDebouncedValue(paymentType, 600);
  const [paymentTermType, setPaymentTermType] = React.useState("");
  const [debouncedPaymentTermType] = useDebouncedValue(paymentTermType, 600);
  const [startDate, setStartDate] = React.useState<any>("");
  const [debouncedStartDate] = useDebouncedValue(startDate, 600);
  const [endDate, setEndDate] = React.useState<any>("");
  const [debouncedEndDate] = useDebouncedValue(endDate, 600);
  const { orderStatus, paymentStatus, deliveryNoteStatus, orderTypes, invoiceStatus, paymentTermConditions, orderPurchaseTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [queryBusiness] = React.useState<string>("");
  const [tableData, setTableData] = React.useState<{ count: number; rows: IOrder[] }>({
    count: 0,
    rows: [],
  });

  const onCLickCheck = (key: string, row: any) => {
    if (key === "select_all") {
      setChecked(row);
    }
    if (key === "unselect_all") {
      setChecked([]);
    }
    if (key === "select") {
      if (checked.some((item: any) => item.id === row.id)) {
        setChecked([...checked.filter((item: any) => item.id !== row.id)]);
      } else setChecked([...checked, row]);
    }
  };

  const onClick = (key: any, record: any) => {
    switch (key) {
      case "edit": {
        navigate(`/supplier-sales/edit/${record?.id}/${record?.receiverBusinessId}`);
        break;
      }
      case "detail": {
        navigate(`/supplier-sales/detail/${record?.id}`);
        break;
      }
      case "cancel": {
        Dialog.confirm("Та борлуулалтын захиалгыг цуцлах гэж байна итгэлтэй байна уу?", async (key) => {
          switch (key) {
            case "confirm": {
              setLoading(true);
              try {
                let res = await OrderApi.cancel(record?.id);
                if (res.success) {
                  setTimeout(() => {
                    ref.current?.reload();
                  }, 200);
                }
                message.success("Амжилттай хүргэлтийн нөхцөлийг устгалаа!");
                setLoading(false);
              } catch (err) {
                setLoading(false);
                message.error((err as HttpHandler)?.message!);
              }
              break;
            }

            default:
          }
        });
        break;
      }
      case "reviewed": {
        setAction(["reviewed", record as any]);
        break;
      }
      case "confirm": {
        setAction(["confirm", record as any, "confirm"]);
        break;
      }
      case "reject": {
        setAction(["reject", record as any, "reject"]);
        break;
      }
      default:
    }
  };

  const reload = async () => {
    ref.current?.reload();
  };

  const onCancel = async () => {
    setActions([]);
  };

  const { data: businessData } = useSwr(`/ord/order/business_select/data/${queryBusiness}`, async () => {
    if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0) {
      try {
        let res = await OrderApi.business({ query: queryBusiness, limit: "" });
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
  });

  const onClear = () => {
    setOrderType("");
    setBusiness("");
    setInvoiceType("");
    setPaymentType("");
    setPaymentTermType("");
    setStartDate("");
    setEndDate("");
  };

  let checkedData: any[];
  let recordData: any[];
  checkedData = (checked || []).filter((item: any) => item?.orderStatus === "AUTHORIZED" && item?.deliveryNote === null);
  recordData = (tableData?.rows || []).filter((item: any) => item?.orderStatus === "AUTHORIZED" && item?.deliveryNote === null);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              data={[{ code: "", sentName: "Бүгд" }, ...(orderStatus || [])]?.map((item: any) => {
                return {
                  label: item.sentName,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={120}
              onChange={(e: string | null) => {
                if (e) setOrderType(e);
              }}
              value={orderType}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"SO статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                data={(businessData || [])?.map((item: any) => {
                  return {
                    label: item.profileName,
                    value: item.id,
                  };
                })}
                placeholder="сонгох"
                leftSectionWidth={150}
                onChange={(e: string | null) => {
                  if (e) setBusiness(e);
                }}
                value={business}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Бизнесийн нэр"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>
          )}
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              data={[{ code: "", name: "Бүгд" }, ...(invoiceStatus || [])].map((item: any) => {
                return {
                  label: item.name,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={200}
              onChange={(e: string | null) => {
                if (e) setInvoiceType(e);
              }}
              value={invoiceType}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Нэхэмжлэх статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <DatePickerInput
              type="range"
              value={[startDate, endDate]}
              placeholder="сонгох"
              leftSectionWidth={100}
              onChange={(e) => {
                if (e[0] && e[1]) {
                  setStartDate(e[0] && e[0]);
                  setEndDate(e[1] && e[1]);
                }
              }}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconCalendarDue size="1rem" stroke={1} />}
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Огноо"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              data={[{ code: "", name: "Бүгд" }, ...(paymentStatus || [])].map((item: any) => {
                return {
                  label: item.name,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={200}
              onChange={(e: string | null) => {
                if (e) setPaymentType(e);
              }}
              value={paymentType}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Төлбөрийн статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Flex gap="xs" align="center">
              <Select
                data={[{ code: "", name: "Бүгд" }, ...(paymentTermConditions || [])].map((item: any) => {
                  return {
                    label: item.name,
                    value: item.code,
                  };
                })}
                placeholder="сонгох"
                leftSectionWidth={200}
                onChange={(e: string | null) => {
                  if (e) setPaymentTermType(e);
                }}
                value={paymentTermType}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px", flex: 1 }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Төлбөрийн нөхцөл"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
              <ActionIcon
                onClick={() => {
                  onClear();
                }}
                color=""
                variant="light"
                size={36}>
                <IconClearAll />
              </ActionIcon>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider color="gray" mt={5} />
        <CustomTable
          onResult={(thisTableData: { count: number; rows: IOrder[] }) => {
            setTableData(thisTableData);
          }}
          ref={ref}
          colCount={39}
          thead={
            <Mantinetable.Thead>
              <Mantinetable.Tr>
                <Mantinetable.Th rowSpan={2}>#</Mantinetable.Th>
                {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
                  <Mantinetable.Th rowSpan={2}>
                    <Checkbox
                      onChange={() => {}}
                      onClick={() => {
                        if (
                          Array.isArray(recordData) &&
                          Array.isArray(checkedData) &&
                          recordData.length === checkedData.length &&
                          recordData.every((val: any, index: number) => val === checkedData[index])
                        ) {
                          onCLickCheck("unselect_all", recordData);
                        } else onCLickCheck("select_all", recordData);
                      }}
                      checked={checkedData?.length === recordData?.length}
                    />
                  </Mantinetable.Th>
                )}
                <Mantinetable.Th rowSpan={2}>Үйлдэл</Mantinetable.Th>
                <Mantinetable.Th colSpan={6} style={{ textAlign: "center" }}>
                  Борлуулалтын захиалга
                </Mantinetable.Th>
                <Mantinetable.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={7}>
                  Харилцагчийн мэдээлэл
                </Mantinetable.Th>
                <Mantinetable.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={4}>
                  Хүргэлтийн мэдээлэл
                </Mantinetable.Th>
                <Mantinetable.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={7}>
                  Төлбөрийн мэдээлэл
                </Mantinetable.Th>
                <Mantinetable.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={6}>
                  PO
                </Mantinetable.Th>
              </Mantinetable.Tr>
              <Mantinetable.Tr>
                <Mantinetable.Th className={`${classes.control}`}>№</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Огноо, цаг</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Төрөл</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`} style={{ minWidth: 100 }}>
                  Статус
                </Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Бүртгэсэн</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хянасан</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Партнер нэр</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>ТТД</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Бизнес нэр</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хүлээн авах салбар</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хүлээн авах ажилтан</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Бүсчлэл</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Чиглэл</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хүргэх огноо</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Статус</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хүргэлт хариуцсан</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хүргэсэн огноо</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Нийт дүн</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Төлсөн дүн</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Төлөх дүн</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Төлбөрийн статус</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Нөхцөл</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Нэхэмжлэх №</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Нэхэмжлэх статус</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>PO дугаар</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Огноо, цаг</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Төрөл</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Статус</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Захиалсан</Mantinetable.Th>
                <Mantinetable.Th className={`${classes.control}`}>Хянасан</Mantinetable.Th>
              </Mantinetable.Tr>
            </Mantinetable.Thead>
          }
          tbody={(rows: IOrder[]) => {
            return (
              <>
                {(rows || []).map((record, index) => {
                  return (
                    <Mantinetable.Tr key={index}>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C">
                          {index + 1}
                        </Text>
                      </Mantinetable.Td>

                      {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
                        <Mantinetable.Td>
                          <Checkbox
                            onChange={() => {}}
                            onClick={() => {
                              onCLickCheck("select", record);
                            }}
                            checked={checked.some((item: any) => item.id === record.id)}
                            disabled={record?.orderStatus === "AUTHORIZED" && record?.deliveryNote === null ? false : true}
                          />
                        </Mantinetable.Td>
                      )}
                      <Mantinetable.Td>
                        <Flex gap="sm">
                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
                            <Tooltip label="Дэлгэрэнгүй" position="bottom">
                              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                                <IconInfoSquare />
                              </Button>
                            </Tooltip>
                          )}
                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
                            <>
                              {record?.orderStatus === "DRAFT" && (
                                <Tooltip label="Засварлах" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                                    <IconEdit />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}

                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isReview).length > 0 && (
                            <>
                              {record?.orderStatus === "REGISTERED" && user?.currentBusiness?.id === record?.senderBusiness?.id && (
                                <Tooltip label="Хянах" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("reviewed", record)}>
                                    <IconEyeCheck />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                            <>
                              {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                                <Tooltip label="Зөвшөөрөх" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("confirm", record)}>
                                    <IconChecks />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                            <>
                              {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                                <Tooltip label="Татгалзах" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("reject", record)} color="red">
                                    <IconExclamationMark color="red" />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_CANCEL").length > 0 && (
                            <>
                              {record?.orderStatus === "REVIEWED" && (
                                <Tooltip label="Цуцлах" position="bottom">
                                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("cancel", record)} color="red">
                                    <IconSquareX color="red" />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Flex>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.salesCode ?? "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {dateTimeSecFormat(record?.createdAt)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {orderTypes?.find((c: any) => c?.code === record?.type)?.name || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                            color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                        </Badge>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Group>
                          <div>
                            <Text size="sm" fw={500} c="#44566C">
                              {record?.type === "SALES"
                                ? (record?.orderedUser?.lastName ? record?.orderedUser?.lastName[0] + "." : "-", record?.orderedUser?.firstName || "-") || "-"
                                : "-"}
                            </Text>
                          </div>
                        </Group>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Group>
                          <div>
                            <Text size="sm" fw={500} c="#44566C">
                              {(record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                                ? (record.respondedUser?.lastName, record.respondedUser?.firstName) || "-"
                                : (record?.reviewedUser?.lastName, record?.reviewedUser?.firstName)}
                            </Text>
                          </div>
                        </Group>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Group>
                          <div>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.receiverBusiness?.partner?.refCode || "-"}
                            </Text>
                            <Text size="sm" fw={500} c="cyan" w="max-content">
                              {record?.receiverBusiness?.partner?.businessName || "-"}
                            </Text>
                          </div>
                        </Group>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Group>
                          <div>
                            <Text size="sm" fw={500} c="#44566C" w="max-content">
                              {record?.receiverBusiness?.refCode || "-"}
                            </Text>
                            <Text size="sm" fw={500} c="cyan" w="max-content">
                              {record?.receiverBusiness?.profileName || "-"}
                            </Text>
                          </div>
                        </Group>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {record?.receiverBranch?.name || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverStaff?.lastName ? record?.receiverStaff?.lastName[0] : "-"}.{record?.receiverStaff?.firstName || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-contet">
                            {record?.receiverStaff?.phone || "-"}
                          </Text>
                        </div>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.region?.name || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.direction?.name || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {dateTimeSecFormat(record?.deliveryDate || "-")}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color} 1px solid`,
                            color: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name ?? "-"}
                        </Badge>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.deliveryNote?.staff?.lastName ? record?.deliveryNote?.staff?.lastName[0] : "-"}.
                            {record?.deliveryNote?.staff?.firstName || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.deliveryNote?.staff?.email || "-"}
                          </Text>
                        </div>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {dateTimeSecFormat(record?.deliveryNote?.deliveredDate)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" ta="end" w="100%" style={{ whiteSpace: "nowrap" }}>
                          {tugrug(record?.totalAmount || 0)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="100%" ta="end" style={{ whiteSpace: "nowrap" }}>
                          {tugrug(record?.paidAmount || 0)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="100%" ta="end" style={{ whiteSpace: "nowrap" }}>
                          {tugrug(record?.amountToPay || 0)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color} 1px solid`,
                            color: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.name || "-"}
                        </Badge>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {record?.paymentTerm?.description || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.invoice?.refCode || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color} 1px solid`,
                            color: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name || "-"}
                        </Badge>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.purchaseCode || "-"}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {dateTimeSecFormat(record?.respondedDate)}
                        </Text>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          color="gray"
                          style={{
                            border: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color} 1px solid`,
                            color: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {orderPurchaseTypes?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
                        </Badge>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        {record?.type === "SALES" ? (
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                            }}
                            styles={{
                              label: {
                                overflow: "visible",
                              },
                            }}>
                            {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                          </Badge>
                        ) : (
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                            }}
                            styles={{
                              label: {
                                overflow: "visible",
                              },
                            }}>
                            {orderStatus.find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
                          </Badge>
                        )}
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#414345" w="max-content">
                            {record?.type === "PURCHASE" ? (record?.orderedUser?.firstName || "-", record?.orderedUser?.firstName || "-") : "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.type === "PURCHASE" ? (record?.orderedUser?.phone || "-", record?.orderedUser?.phone || "-") : "-"}
                          </Text>
                        </div>
                      </Mantinetable.Td>
                      <Mantinetable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#243f60" w="max-content">
                            {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                              ? (record.respondedUser?.lastName, record.respondedUser?.firstName) || "-"
                              : (record?.reviewedUser?.lastName, record?.reviewedUser?.firstName)}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                              ? record.respondedUser?.phone || "-"
                              : record?.reviewedUser?.phone || "-"}
                          </Text>
                        </div>
                      </Mantinetable.Td>
                    </Mantinetable.Tr>
                  );
                })}
              </>
            );
          }}
          name="supplier-sales.list"
          filters={{
            type: "SALES",
            orderStatus: debouncedOrderType,
            businessId: debouncedBusiness,
            startDate: debouncedStartDate,
            endDate: debouncedEndDate,
            invoiceStatus: debouncedInvoiceType,
            paymentStatus: debouncedPaymentType,
            paymentTermCondition: debouncedPaymentTermType,
            excel: `${false}`,
          }}
          loadData={(data) => OrderApi.list(data!)}
        />
      </Flex>
      <Modal
        opened={action[0] === "confirm" || action[0] === "reject"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <SupplierSalesDynamicConfirmForm action={action && action[1]} setActions={setAction} constant={action && action[2]} reload={reload} />
      </Modal>

      <Modal
        opened={action[0] === "reviewed"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <SupplierSalesDynamicReviewForm action={action && action[1]} setActions={setAction} reload={reload} />
      </Modal>

      <Modal
        opened={actions[0] === "delivery"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="md"
        padding={0}
        centered>
        <SupplierSalesDeliveryForm action={actions} onCancel={onCancel} reload={reload} setChecked={setChecked} />
      </Modal>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "8px!important",
  },
  style: {
    width: "auto",
    textAlign: "center",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
}));
