import { Alert, AppShell, Popover, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconAlertCircle, IconBox, IconChartPie, IconListDetails, IconReceipt, IconTruckDelivery, IconUserSearch } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { useScreenDetector } from "../screen-detector";

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile } = useScreenDetector();

  const itemsBu = [
    {
      title: "Дашбоард",
      icon: IconChartPie,
      color: "cyan",
      link: "/",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_DASH").length > 0 ? true : false,
    },
    {
      title: "Худалдан авалт",
      icon: IconListDetails,
      color: "cyan",
      link: "/buyer-sales",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_NET").length > 0 ? true : false,
    },
    {
      title: "Гэрээт нийлүүлэгчид",
      icon: IconUserSearch,
      color: "cyan",
      link: "/buyer-customer",
      disable: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_NET") ? true : false,
    },
  ];

  const itemsSu = [
    {
      title: "Дашбоард",
      icon: IconChartPie,
      color: "cyan",
      link: "/",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_DASH").length > 0 ? true : false,
    },
    {
      title: "Борлуулалтын захиалга",
      icon: IconListDetails,
      color: "cyan",
      link: "/supplier-sales",
      disabled: false,
      view:
        (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_SPLIT").length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0
          ? true
          : false,
    },
    {
      title: "Харилцагчийн захиалга",
      icon: IconBox,
      color: "cyan",
      link: "/supplier-purchase",
      disabled: false,
      view:
        (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_BCK_ORD").length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0
          ? true
          : false,
    },
    {
      title: "Dropshipping захиалга",
      icon: IconBox,
      color: "cyan",
      link: "/supplier-drop-shipping",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 ? true : false,
    },
    {
      title: "Хүргэлт удирдлага",
      icon: IconTruckDelivery,
      color: "cyan",
      link: "/supplier-delivery",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 ? true : false,
    },
    {
      title: "Ачилт удирдлага",
      icon: IconBox,
      color: "cyan",
      link: "/supplier-shipping",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_PS_LIST").length > 0 ? true : false,
    },
    {
      title: "Гэрээт харилцагч",
      icon: IconUserSearch,
      color: "cyan",
      link: "/supplier-customer",
      disabled: false,
      view: (user?.permissions || []).filter((c) => c.module === "ORDER" && c.code === "ORD_NET").length > 0 ? true : false,
    },
  ];

  return (
    <AppShell.Navbar p="md" style={{ gap: 20 }}>
      {user?.currentBusiness?.type === "SUPPLIER"
        ? (itemsSu || []).filter((c) => c.view).map((item, index) => <NavItem item={item} key={index} setOpened={setOpened} opened={isMobile && opened} />)
        : (itemsBu || []).filter((c) => c.view).map((item, index) => <NavItem item={item} key={index} setOpened={setOpened} opened={isMobile && opened} />)}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "cyan" });

  return (
    <CommingSoon visible={item.disabled}>
      <NavLink className={cx(classes.item, item.disabled && "disabled")} to={!item.disabled ? item.link : "#"}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={7} lh="16px" fw="400">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const CommingSoon = ({ children, visible = false }: { children: JSX.Element; visible?: boolean }) => {
  if (!visible) return children;

  return (
    <Popover width={400} position="right" withArrow shadow="sm">
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown p={0}>
        <Alert icon={<IconAlertCircle size={16} />} title="Санамж!" style={{ backgroundColor: "#fff" }} color="indigo.7">
          Тун удахгүй ...
        </Alert>
      </Popover.Dropdown>
    </Popover>
  );
};

const useStyles = createStyles((theme, { color }: { color: string }, u) => {
  const icon = u.ref("icon");

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
  };
});
